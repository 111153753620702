import React from 'react'



export default function Experience(props) {
  const sectionStyle = {
      marginBottom: '4rem'
  }
    return(<section class="section" id="experience" style={sectionStyle}>
          <div class="container">
            <h1 class="title">Experience</h1>
          
            <div class="timeline is-centered">
              <header class="timeline-header"><span class="tag is-medium is-dark">2024</span></header>

              <div class="timeline-item"><div class="timeline-marker is-success"></div><div class="timeline-content"></div></div>


              <div class="timeline-item is-success">
                    <div class="timeline-marker is-image is-48x48">
                    <a href="https://aws.amazon.com/">
                        <img src={process.env.PUBLIC_URL + '/aws-color.jpeg'} alt="" class="center" />
                    </a>
                    </div>

                    <div class="timeline-content">
                    <p class="heading">Summer 2024</p>
                    <h1 class="title is-4">Amazon Web Services</h1>
                    <p class="subtitle is-6">Software Development Engineer Intern</p>
                        <div class="MuiPaper-root MuiCard-root jss1 MuiPaper-elevation0 MuiPaper-rounded">
                        <p aria-expanded="false" aria-label="show more">Developed an automated bot using Java, DynamoDB, and AWS Lambda for the EC2 team to monitor pull requests, detect security deviations in EC2 environment permissions, and alert security engineers.</p>
                        <p aria-expanded="false" aria-label="show more">Decreased average verification time from 2 minutes to 5 seconds and enhanced overall system security.</p>
                        </div>
                    </div>
                </div>

              <div class="timeline-item is-success">
                    <div class="timeline-marker is-image is-48x48">
                    <a href="https://aws.amazon.com/">
                        <img src={process.env.PUBLIC_URL + '/aws-color.jpeg'} alt="" class="center" />
                    </a>
                    </div>

                    <div class="timeline-content">
                    <p class="heading">Summer 2023</p>
                    <h1 class="title is-4">Amazon Web Services</h1>
                    <p class="subtitle is-6">Software Development Engineer Intern</p>
                        <div class="MuiPaper-root MuiCard-root jss1 MuiPaper-elevation0 MuiPaper-rounded">
                        <p aria-expanded="false" aria-label="show more">Developed a security management tool using TypeScript, AWS S3, and React that automates API permissions onboarding and modifications in the EC2 droplets environment.</p>
                        <p aria-expanded="false" aria-label="show more">Decreased the deployment time of permission changes from 2 weeks to 2 days and increased the security bar by adding more rigorous verification steps to the process.</p>
                        </div>
                    </div>
                </div>
            
                <div class="timeline-item is-success">
                    <div class="timeline-marker is-image is-48x48">
                    <a href="https://tufts.edu/">
                        <img src={process.env.PUBLIC_URL + '/jumbo.jpeg'} alt="" class="center" />
                    </a>
                    </div>

                    <div class="timeline-content">
                    <p class="heading">September 2021 - December 2024</p>
                    <h1 class="title is-4">Tufts University</h1>
                    <p class="subtitle is-6">BS in Applied Mathematics and Computer Science</p>
                        <div class="MuiPaper-root MuiCard-root jss1 MuiPaper-elevation0 MuiPaper-rounded">
                        <p aria-expanded="false" aria-label="show more">Senior interested in compilers, backend software engineering and distributed systems.</p>
                        <p aria-expanded="false" aria-label="show more">Relevant Coursework: Data Structures, Algorithms, Multivariable Calculus, Linear Algebra, Discrete Mathematics, Real Analysis, Probability, Statistics, Mathematical Modelling, Operating Systems, Non-linear Dynamics & Chaos, Numerical Linear Algebra, Network Science, Machine Structure & Assembly Language Programming, Probabilistic Machine Learning.</p>
                        <p aria-expanded="false" aria-label="show more">Additional Coursework: Deep Learning Nanodegree (Udacity).</p>
                        <p aria-expanded="false" aria-label="show more">Advised by <a href="https://math.tufts.edu/people/faculty/james-murphy" style={{ textDecoration: 'none' }} target="_blank">James Murphy</a> and <a href="https://engineering.tufts.edu/cs/people/faculty/lenore-cowen" style={{ textDecoration: 'none' }} target="_blank">Lenore Cowen</a>.</p>
                        </div>
                    </div>
                </div>

              <div class="timeline-item is-success">
                <div class="timeline-marker is-image is-48x48">
                    <a href="https://neuralmind.ai/en/home-en/">
                        <img src={process.env.PUBLIC_URL + '/neuralmind.jpeg'} alt="" class="center" />
                    </a>
                </div>

                <div class="timeline-content">
                  <p class="heading">February 2020 - May 2021 (COVID Gap-Year)</p>
                  <h1 class="title is-4">NeuralMind</h1>
                  <p class="subtitle is-6">Software Developer</p>
                    <div class="MuiPaper-root MuiCard-root jss1 MuiPaper-elevation0 MuiPaper-rounded">
                        <p class="experiencedesc" aria-expanded="false" aria-label="show more">Developed a data-scraping platform to collect and analyze data on 2,000 customer suppliers from publicly available Brazilian government certificates, assessing potential legal and financial risks for clients.{"\n"}</p>
                        <p class="experiencedesc" aria-expanded="false" aria-label="show more">Extracted and processed data from 40 types of certificates issued by 27 state government websites, identifying risks such as debts, environmental fines, and the use of slave labor.{"\n"}</p>
                        <p class="experiencedesc" aria-expanded="false" aria-label="show more">Designed SQL-based database models and implemented distributed systems to manage and analyze over 80,000 certificates monthly, delivering actionable insights into supplier risk.</p>
                    </div>
                </div>
              </div>

            </div>
          </div>
        </section>)
}