import React from 'react'
import { BiWorld } from "react-icons/bi"
import { GiSaxophone, GiMusicalNotes, GiCloudDownload } from "react-icons/gi";



export default function AboutMe(props) {

    const aboutMeStyle = {
        backgroundImage: `linear-gradient(to right, rgba(0, 224, 255, 1), rgba(187, 0, 249, 0.8))`
    }
    
    const imagestyle = {
        height: '180px',
        width:'180px'
    }

    const downloadStyle = {
      color: "#ffffff",
      backgroundColor: '#00000000',
      textDecoration: 'none',
    }
    
    const buttonStyle = {
      marginLeft: '5px',
      marginTop: '1px'
    }

    return(
        <section class="section" id="aboutMe" style={aboutMeStyle}>
          <div class="container has-text-centered">
            <div class="is-centered">
              <figure class="image container is-180x180">
                <img src={process.env.PUBLIC_URL + '/img/profile-pic.jpg'} class="is-rounded" style={imagestyle}></img>
              </figure>
            </div>
            <div class="column download has-text-centered is-centered">
              <p>
                <a href="files/lucaspadovan-september-2024.pdf" class="button" style={downloadStyle} target="_blank">Download Resume <GiCloudDownload style={buttonStyle}/></a>
                </p>

            </div>
            <p class="subtitle is-5 has-text-white has-text-weight-light summary-text">
              Hey there! I am Lucas Padovan, an undergraduate student at Tufts University majoring in Applied Mathematics and Computer Science.
            </p>
            <div class="container interests">
              <div class="field is-grouped is-grouped-multiline is-grouped-centered has-text-centered">
                <div class="control">
                  <div class="tags has-addons">
                    <span class="tag is-dark">Saxophone</span>
                    <span class="tag is-success"><GiSaxophone color='#e0e0e0'/></span>
                  </div>
                </div>
                <div class="control">
                  <div class="tags has-addons">
                    <span class="tag is-dark">Jazz</span>
                    <span class="tag is-success"><GiMusicalNotes color='#e0e0e0'/></span>
                  </div>
                </div>
                <div class="control">
                  <div class="tags has-addons">
                    <span class="tag is-dark">Travel Bug</span>
                    <span class="tag is-success"><BiWorld color='#e0e0e0'/></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    )
}