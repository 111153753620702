import React, { Component }  from 'react';
import 'bulma/css/bulma.min.css';
import Header from './components/header'
import AboutMe from './components/about-me'
import Experience from './components/experience'
import Skills from './components/skills'
import Projects from './components/projects'
import Material from './components/material'
import Footer from './components/footer'
import {
  Routes,
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Algorithms from './materials/algorithms'
import './App.css';



function App() {
  const backimagestyle = {
    background: `url(${process.env.PUBLIC_URL + '/img/tufts8.jpg'}) center / cover` ,
  };

  const footerstyle = {
    paddingBottom: 20,
     zIndex: 10
  }

  const aboutMeStyle = {
    backgroundImage: `linear-gradient(to right, rgba(0, 224, 255, 1), rgba(187, 0, 249, 0.8))`
  }

  const iconstyle = {
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'center'
  }

  const imagestyle = {
    height: '180px',
    width:'180px'
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/algorithms" element={<Algorithms/>}/>
      </Routes>
    </Router>
  );
}

function Home() {
  return (
    <div className="App">
    <Header />                  

    <main>
      <AboutMe />
      <Experience />
      <Skills />
      <Projects />
      {/* <Material /> */}
      <Footer />
    </main>
  </div>
  )
}


export default App;