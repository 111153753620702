import React from 'react'
import { IoMailOutline, IoLogoLinkedin, IoLogoGithub } from 'react-icons/io5'
import TypeWriterEffect from 'react-typewriter-effect';


export default function Header(props) {
    const backimagestyle = {
        background: `url(${process.env.PUBLIC_URL + '/img/tufts8.jpg'}) center / cover` ,
    };

    const footerstyle = {
    paddingBottom: 20,
        zIndex: 10
    }

    return(
        <header className="App-header">
          <section class="hero is-fullheight has-bg-image" style={backimagestyle} >
            <div class="hero-head">
              <nav class="navbar is-transparent has-position-absolute	has-text-white">
                  
                  <div class="container has-text-white">
                      <div class="navbar-brand">
                          <a class="navbar-item title is-unselectable my-name has-text-white" href="/" style={{ textDecoration: 'none' }}>Lucas Padovan</a>
                      </div>
                      <div class="navbar-menu nav-menu null">
                          <div class="navbar-end">
                            <a href="#aboutMe" class="navbar-item is-unselectable has-text-white" style={{ textDecoration: 'none' }}>About Me</a>
                            <a href="#experience" class="navbar-item is-unselectable has-text-white" style={{ textDecoration: 'none' }}>Experience</a>
                            <a href="#projects" class="navbar-item is-unselectable has-text-white" style={{ textDecoration: 'none' }}>Projects</a>
                            {/* <a href="#projects" class="navbar-item is-unselectable has-text-white" style={{ textDecoration: 'none' }}>Material</a>
                            <a href="#projects" class="navbar-item is-unselectable has-text-white" style={{ textDecoration: 'none' }}>Music</a> */}
                          </div>
                      </div>
                  </div>
                  
              </nav>
            </div>

            <div class="hero-body">
              <div class="container">
                <p class="subtitle is-5 has-text-weight-light has-text-white">I'm a(n)</p>
                  <h1 class="title">
                      <span>
                      <TypeWriterEffect
                          textStyle={{
                            fontFamily: 'Gill Sans',
                            color: '#eda642',
                            fontWeight: 500,
                            fontSize: '1.5em',
                          }}
                          startDelay={500}
                          cursorColor="#eda642"
                          multiText={[
                            'Applied Mathematics Student',
                            'Compilers Enthusiast',
                            'Computer Science Student'
                          ]}
                          multiTextLoop
                          multiTextDelay={1000}
                          typeSpeed={60}
                        />
                      </span>
                  </h1>
                  <h2 class="subtitle has-text-white">
                      based in Medford, MA
                  </h2>
                  <h2 class="subtitle has-text-white">
                    <i>lucas [dot] padovan [at] tufts [dot] edu</i>
                  </h2>
                </div>
            </div>

            <div class="hero-foot" style={footerstyle}>
              <div class="columns is-mobile is-centered">
                <div class="column"></div>
                <div class="column has-text-centered">
                    <a href="mailto:lucas.padovan@tufts.edu" target="blank" class=" is-hovered" title="Email">
                      <span class="icon is-medium">
                        <IoMailOutline color='#e0e0e0' size='7em'/>
                      </span>
                    </a>
                </div>
                <div class="column has-text-centered">
                    <a href="https://www.linkedin.com/in/lucas-padovan-22397a176/" target="blank" class="is-hovered" title="Linkedin">
                      <span class="icon is-medium is-">
                        <IoLogoLinkedin color='#e0e0e0' size='7em'/>
                      </span>
                    </a>
                </div>
                <div class="column has-text-centered">
                    <a href="https://github.com/lucas-padovan" target="blank" class="is-hovered" title="Github">
                      <span class="icon is-medium is-">
                        <IoLogoGithub color='#e0e0e0' size='7em'/>
                      </span>
                    </a>
                </div>
                <div class="column"></div>
              </div>
            </div>
          </section>
      </header>
    )
}