import React from 'react'
import { GiSaxophone, GiMusicalNotes } from "react-icons/gi";
import { BiWorld } from "react-icons/bi"
import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

const IMG_URL = process.env.PUBLIC_URL + '/img/skills';


function SkillRow1() {
    const media = {
        backgroundColor: 'white'
    }
    const imagestyle = {
        height: '48px',
        width:'48px'
    }

    return (
      <React.Fragment>
        <Grid container direction={'row'} spacing={4} alignItems="center" justifyContent="center" justify="center">
            <Grid item xs={1.5} my={1}>
                <Card sx={{ boxShadow: 4, maxWidth: 200, maxHeight:100, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <CardMedia component="img" style={{backgroundColor: "white"}} sx={{m: 1, width: 48, height:48 }} image={IMG_URL + '/pytorch-logo.png'}/>
                    <CardContent sx={{mt: "auto",}}><Typography variant="body2" color="text.secondary" sx={{mt: "auto",}}>Pytorch</Typography></CardContent>
                </Card>
            </Grid>
            <Grid item xs={1.5} my={1}>
                <Card sx={{ boxShadow: 4, maxWidth: 200, maxHeight:100, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <CardMedia component="img" style={{backgroundColor: "white"}}  sx={{m: 1, width: 48, height:48 }} image={IMG_URL + '/django-logo.png'}/>
                            <CardContent sx={{mt: "auto",}}><Typography variant="body2" color="text.secondary" sx={{mt: "auto",}}>Django</Typography></CardContent>
                </Card>
            </Grid>
            <Grid item xs={1.5} my={1} sx={{ mx: 2, alignContent: 'center'}}>
                <Card sx={{ boxShadow: 4, mx: 0.5, maxWidth: 200, maxHeight:100, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <CardMedia component="img"  sx={{m: 1, width: 48, height:48 }} image={IMG_URL + '/git-logo.png'}/>
                    <CardContent sx={{mt: "auto",}}><Typography variant="body2" color="text.secondary" sx={{mt: "auto",}}>Git</Typography></CardContent>
                </Card>
            </Grid>
            <Grid item xs={1.5} my={1}>
                <Grid item >
                    <Card alignItems="center" justifyContent="center" sx={{ boxShadow: 4, maxWidth: 200, maxHeight:100, display: 'flex', flexDirection: 'row', bgcolor: 'background.paper', alignItems: 'center'}}>
                        <CardMedia component="img"  sx={{m: 1, width: 48, height:48 }} image={IMG_URL + '/c++-logo.png'}/>
                        <CardContent justifyContent="center" sx={{mt: "auto"}}><Typography  sx={{mt: "auto",}} variant="body2" style={media} color="text.secondary">C++</Typography></CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
      </React.Fragment>
    );
}

/* <Grid item >
    <Card alignItems="center" justifyContent="center" sx={{ maxWidth: 200, maxHeight:100, display: 'flex', flexDirection: 'row', bgcolor: 'background.paper',}}>
        <CardMedia component="img"  sx={{ width: 60, opacity:3 }} image={IMG_URL +  '/c++-logo.png'}/>
        <CardContent><Typography variant="body2" style={media} color="text.secondary">C++</Typography></CardContent>
    </Card>
</Grid> */


function SkillRow2() {
    return(
        <React.Fragment>
            <Grid container direction={'row'} spacing={4} alignItems="center" justifyContent="center" justify="center">
                <Grid item xs={1.5} my={1}>
                    <Card alignItems="center" justifyContent="center" sx={{ boxShadow: 4, maxWidth: 200, maxHeight:100, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <CardMedia component="img"  sx={{m: 1, width: 48, height:48 }} image={IMG_URL +'/node-logo.png'}/>
                    <CardContent sx={{mt: "auto",}}><Typography variant="body2" color="text.secondary" sx={{mt: "auto",}}>Node.js</Typography></CardContent>
                    </Card>
                </Grid>

                <Grid item xs={1.5} my={1} sx={{maxHeight:100}}>
                    <Card sx={{ boxShadow: 4, maxWidth: 200, maxHeight:100, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <CardMedia component="img"  sx={{m: 1, width: 48, height:48 }} image={IMG_URL + '/python-logo.png'}/>
                    <CardContent sx={{mt: "auto",}}><Typography variant="body2" color="text.secondary" sx={{mt: "auto"}}>Python</Typography></CardContent>
                    </Card>
                </Grid>

                <Grid item xs={1.5} my={1}>
                    <Card sx={{ boxShadow: 4, maxWidth: 200, maxHeight:100, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <CardMedia component="img"  sx={{m: 1, width: 48, height:48 }} image={IMG_URL + '/react-logo.jpeg'}/>
                    <CardContent sx={{mt: "auto",}}><Typography variant="body2" color="text.secondary" sx={{mt: "auto",}}>React</Typography></CardContent>
                    </Card>
                </Grid>

                <Grid item xs={1.5} my={1}>
                    <Card sx={{ boxShadow: 4, maxWidth: 200,  maxHeight:100,display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <CardMedia component="img"  sx={{m: 1, width: 48, height:48 }} image={IMG_URL + '/mysql-logo.svg'}/>
                    <CardContent sx={{mt: "auto",}}><Typography variant="body2" color="text.secondary" sx={{mt: "auto",}}>MySQL</Typography></CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default function Skills(props) {
    const sectionStyle = {
        marginBottom: '4rem'
    }
    return(
        <section id="skills" style={sectionStyle}>
          <div class="container">
            <h1 class="title">Skills</h1>
            <div class="skills is-centered">
                <Grid container direction={'column'} spacing={4} my={2}>
                    <SkillRow1 />
            
                    <SkillRow2 />
                </Grid>
            </div>
          </div>
        </section>
    )
}