import React from 'react'
import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActionArea from '@mui/material/CardActionArea';
import ProjectCard from './project-card'
import Paper from '@mui/material/Paper';

const IMG_URL = process.env.PUBLIC_URL + '/img/projects/';


export default function Projects(props) {
    const sectionStyle = {
        marginBottom: '4rem'
    }
    const paperProject = {
        borderRadius: 10
    }

    return (
        <section id="projects" style={sectionStyle}>
          <div class="container">
            <h1 class="title">Projects</h1>
            <Grid container direction={'row'}  justifyContent="center" spacing={50} flex={1}>

                <Grid item xs={1.5} my={1} ml={-30}>
                    <ProjectCard title="Bike Sharing Predictor" desc="I implemented a Neural Network from scratch to predict the number of bikeshare users in a given day. Numpy + Matplotlib" imageLocation='bike.jpeg' git='https://github.com/lucas-padovan/BikeSharing'/>
                </Grid>

                <Grid item xs={1.5} my={1}>
                    <ProjectCard title="Landscape Classifier" desc="I used Convolutional Neural Networks to build an image classifier capable of labeling 50 different landscapes." imageLocation='edinburg.jpeg' git='https://github.com/lucas-padovan/LandscapeClassifier'/>
                </Grid>

                <Grid item xs={1.5} my={1}>
                    <ProjectCard title="Script Generator" desc="I'm using Recurrent Neural Networks and Pytorch to generate new lines based on the previous ones." imageLocation='acting.jpeg' git='https://github.com/lucas-padovan/ScriptGenerator' />
                </Grid>

            </Grid>
          </div>
        </section>
    )
}