import React from 'react';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import ReactMarkdown from 'react-markdown'

var Latex = require('react-latex');



export default function Algorithms() {
    return (
        <div id="wrapper">
            <div id="sidebar-wrapper">
                <div class="sidebar-top">
                    <li class="sidebar-title">
                        <a></a>
                    </li>
                    <li class="sidebar-brand">
                        <a>
                            <div>
                                <span>Algorithms</span>
                            </div>
                        </a>
                    </li>
                </div>
                <ul class="sidebar-nav">
                    <li class="active">
                        <div class="dropdown-btn">
                            <a>Introduction</a>
                        </div>
                        <div class="dropdown-container">
                            <a>
                                <span>
                                    Complexity
                                </span>
                            </a>
                            <a>
                                <span>
                                    Big O Notation
                                </span>
                            </a>
                            <a>
                                <span>
                                    Big Theta Notation
                                </span>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>

            <ReactMarkdown>
                # Hello, *world*!
                Inline math equation: $\omega = d\phi / dt$. Display
math should get its own line like so:
            </ReactMarkdown>

        </div>
    )
};