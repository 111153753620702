import React from 'react'
import { MdNightlight } from 'react-icons/md'

export default function Footer(props) {
    const aboutMeStyle = {
        backgroundImage: `linear-gradient(to right, rgba(187, 0, 249, 0.8), rgba(0, 0, 0, 1)`,
        height: 20
    }

    const footerP = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        margin: "0.5rem"
    }

    const logoStyle = {
        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "center",
        // alignItems: "center",
        // size: 40,
        align: "center",
        verticalAlign: "center",
        marginTop: -10
    }
    
    return (
        <footer class="footer" style={aboutMeStyle} >
            <div class="content has-text-centered has-text-white" style={footerP}>
                <p style={{ marginRight: '1rem' }}>Built at{'    '}</p>
                <MdNightlight style={logoStyle}/>
                <p style={{ marginLeft: '1rem' }}>{'   '}by Lucas Padovan</p>
            </div>
        </footer>
    )
}