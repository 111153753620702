import React from 'react'
import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActionArea from '@mui/material/CardActionArea';
import CodeIcon from '@mui/icons-material/Code';
import Avatar from '@mui/material/Avatar';
import CardActions from '@mui/material/CardActions';
import GitHubIcon from '@mui/icons-material/GitHub';
import IconButton from '@mui/material/IconButton';




import {AiFillGithub} from 'react-icons/ai'


const IMG_URL = process.env.PUBLIC_URL + '/img/projects/';


export default function ProjectCard({title, desc, imageLocation, git}) {

    const [projectTitle, setProjectTitle] = React.useState(title);
    const [projectImage, setProjectImage] = React.useState(imageLocation);
    const [projectDescription, setProjectDescription] = React.useState(desc);
    const [gitRepo, setGitRepo] = React.useState(git);

    return (
        <Card style={{backgroundColor: "#868686"}} variant="outlined" alignItems="center" justifyContent="center" sx={{ display: 'flex', height: 400, width: 250, flexDirection: 'row'}} >
            <CardActionArea href={gitRepo}>
                <CardHeader justifyContent="flex-start" style={{backgroundColor: "#575757"}} title={projectTitle} titleTypographyProps={{fontSize: '1rem', align: 'center', color: '#FFFFFF', fontWeight: 'bold'}}>
                </CardHeader>
                <CardMedia
                component="img"
                height="194"
                image={IMG_URL + projectImage}
                alt="Coming Soon"
                sx={{ height: 150}}
                />
                <CardContent justifyContent="center">
                    <Typography variant="body2" color="text.secondary" align="center" alignItems="center" color='#FFFFFF' justifyContent="center">
                    {projectDescription}
                    </Typography>
                </CardContent>
                <CardActions sx={{flexDirection: "column", alignContent: 'flex-end', height:50, justifyContent:"center",  height:'20%'}}>
                    <IconButton href={gitRepo}>
                        <GitHubIcon/>
                    </IconButton>
                </CardActions>
            </CardActionArea>
        </Card>
    )
}